import "core-js/modules/es.array.push.js";
import axios from 'axios';
import { authorization } from '../../../../public/static/js/headers';
export default {
  name: 'Footer',
  data() {
    return {
      active: 'home'
    };
  },
  mounted() {},
  methods: {
    async tabbarChange(name) {
      if (name === 'my') {
        await axios.post('/api/appMember/isAgent', {}, authorization()).then(res => {
          if (res.data.data === 1) {
            this.$router.push('/my');
          } else {
            this.$router.push('/registerAgent');
          }
        });
      }
    }
  }
};